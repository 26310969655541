import { Control, Grouping, Menu, Pointer } from '@yleisradio/areena-types';
import React, { useCallback } from 'react';
import styles from './ViewMenus.module.scss';
import cls from 'classnames';
import { Link } from 'components/Link';
import { useRouter } from 'next/router';
import { useUILanguage } from 'hooks/useUILanguage';
import { useAreenaService } from 'contexts/AreenaServiceContext';
import { pointerToClientURL } from 'utils/pointer';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import { useTranslation } from 'hooks/useTranslation';

type Props = {
  menus: Menu[] | undefined;
};

const isTabMenu = (menu: Menu): boolean => menu.tag === 'section-menu';

const useActivePointerPath = () => {
  const { asPath } = useRouter();
  const language = useUILanguage();
  const { areenaService } = useAreenaService();
  const currentPathWithoutQuery = asPath.split('?')[0];

  return useCallback(
    (pointer: Pointer) =>
      pointerToClientURL(pointer, language, areenaService)?.split('?')[0] ===
      currentPathWithoutQuery,
    [language, areenaService, currentPathWithoutQuery]
  );
};

const getControls = (groupings: Grouping[]) =>
  groupings.reduce(
    (acc: Control[], grouping) =>
      acc.concat(grouping.groupings, grouping.controls),
    []
  );

const ViewMenus = ({ menus }: Props) => {
  const t = useTranslation();
  const headingId = 'section-menu-heading';
  const tabMenus = menus?.find(isTabMenu);
  const isActiveMenuItem = useActivePointerPath();

  if (!tabMenus) return null;

  const controls = getControls(tabMenus.groupings);

  return (
    <nav className={styles.root} aria-labelledby={headingId}>
      <VisuallyHidden asChild>
        <h2 id={headingId}>{t('sectionMenu')}</h2>
      </VisuallyHidden>
      {controls.map((control) => {
        const pointer = control.destination;

        return pointer ? (
          <Link
            key={pointer.uri}
            pointer={pointer}
            aria-current={isActiveMenuItem(pointer)}
            className={cls(
              styles.link,
              isActiveMenuItem(pointer) && styles.linkActive
            )}
          >
            {control.title}
          </Link>
        ) : null;
      })}
    </nav>
  );
};

export default ViewMenus;
