import { View, ViewMeta } from '@yleisradio/areena-types';
import { useUILanguage } from 'hooks/useUILanguage';
import React from 'react';
import { SchemaOrgNode } from 'services/schema-org/types';
import { Query } from 'types/query';
import { AppBanner } from './AppBanner';
import { GenericMetadata } from './GenericMetadata';
import { SchemaOrg } from './SchemaOrg';
import { SocialMediaTags } from './SocialMediaTags';
import { useTitle } from './useTitle';
import { getRssFeedUrl } from 'utils/url';
import { isAreenaProgramItem, isTVItem } from 'utils/item';

interface Props {
  path: string;
  query: Query;
  schemaOrgNodes: SchemaOrgNode[];
  view: View;
  viewMeta: ViewMeta;
  metaImageAspectRatio: '16:9' | '1:1';
  slugs: Record<string, string> | null;
}

export const ViewMetadata: React.FunctionComponent<Props> = ({
  path,
  query,
  schemaOrgNodes,
  view,
  viewMeta,
  metaImageAspectRatio,
  slugs,
}) => {
  const { description, image, item, ogType, series } = viewMeta;

  const { duration, releaseDate } = item || {};

  const language = useUILanguage();
  const title = useTitle({ view, viewMeta });
  const siteName = language === 'sv' ? 'Yle Arenan' : 'Yle Areena';
  const rssFeedUrl = item ? getRssFeedUrl(item, language) : null;
  const robots =
    item && isTVItem(item) && isAreenaProgramItem(item)
      ? `${viewMeta.robots ? `${viewMeta.robots}, ` : ''}max-image-preview:large`
      : viewMeta.robots;

  return (
    <>
      <GenericMetadata
        description={description}
        path={path}
        query={query}
        robots={robots}
        rssFeedUrl={rssFeedUrl}
        siteName={siteName}
        title={title}
        slugs={slugs}
      />
      <AppBanner path={path} />
      <SchemaOrg nodes={schemaOrgNodes} />
      <SocialMediaTags
        description={description}
        duration={duration}
        image={image}
        ogType={ogType}
        path={path}
        query={query}
        releaseDate={releaseDate}
        series={series}
        siteName={siteName}
        title={title}
        metaImageAspectRatio={metaImageAspectRatio}
      />
    </>
  );
};
