import React, { useId } from 'react';
import styles from './DSNotification.module.scss';
import AlertTriangleIcon from 'assets/alertTriangle.svg';
import CheckIcon from 'assets/check.svg';
import CloseIcon from 'assets/close.svg';
import InfoIcon from 'assets/infoIcon.svg';
import classNames from 'classnames';
import { Button } from 'components/Button';
import { useTranslation } from 'hooks/useTranslation';

/** @see https://www.figma.com/file/R9f86Qki1JqRm5VAylHtaE/Yle-Components-(Dark)?type=design&node-id=4239-48120&mode=dev */

export type Type = 'info' | 'success' | 'warning' | 'error';
export type State = 'primary' | 'secondary' | 'text';

const Icon: React.FC<{ type: Type } & React.SVGAttributes<SVGElement>> = ({
  type,
  ...props
}) => {
  switch (type) {
    case 'info':
    case 'warning':
      return <InfoIcon {...props} />;
    case 'success':
      return <CheckIcon {...props} />;
    case 'error':
      return <AlertTriangleIcon {...props} />;
    default:
      return null;
  }
};

type Props = {
  type: Type;
  state: State;
  title: string;
  subtitle?: string;
  close?: () => void;
};

export const DSNotification: React.FC<Props> = ({
  title,
  subtitle,
  type,
  state,
  close,
}) => {
  const t = useTranslation();
  const titleId = useId();

  return (
    <aside
      aria-labelledby={titleId}
      className={classNames(
        styles.root,
        type === 'info' && styles.rootInfo,
        type === 'success' && styles.rootSuccess,
        type === 'warning' && styles.rootWarning,
        type === 'error' && styles.rootError,
        state === 'primary' && styles.rootPrimary,
        state === 'secondary' && styles.rootSecondary,
        state === 'text' && styles.rootText
      )}
    >
      <Icon aria-hidden className={styles.icon} type={type} />
      <div className={styles.text}>
        <h3 className={styles.title} id={titleId}>
          {title}
        </h3>{' '}
        {subtitle ? <p className={styles.subtitle}>{subtitle}</p> : null}
      </div>
      {close && (
        <Button
          text={t('close')}
          iconOnly
          iconLeft={CloseIcon}
          onClick={close}
          padding="none"
          variant="text"
          size="md"
        />
      )}
    </aside>
  );
};
