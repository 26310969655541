import Head from 'next/head';
import React from 'react';
import { openGraphImageUrl } from 'utils/cloudinary';
import { SocialMediaTagsProps } from './SocialMediaTagsProps';

export const Twitter: React.FunctionComponent<SocialMediaTagsProps> = ({
  description,
  image,
  title,
  metaImageAspectRatio,
}) => {
  /*
  Twitter uses available Open Graph tags as fallback but re-defining them here
  provides possibly better support
  */
  const tags = new Map<string, string>().set(
    'twitter:card',
    'summary_large_image'
  );

  if (title) {
    tags.set('twitter:title', title);
  }
  if (description) {
    tags.set('twitter:description', description);
  }
  if (image) {
    tags.set('twitter:image', openGraphImageUrl(image, metaImageAspectRatio));
  }

  return (
    <Head>
      {Array.from(tags).map(([name, content]) => (
        <meta key={name} name={name} content={content} />
      ))}
    </Head>
  );
};
