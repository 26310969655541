import { View, ViewMeta } from '@yleisradio/areena-types';
import { useTranslation } from 'hooks/useTranslation';
import { useUILanguage } from 'hooks/useUILanguage';
import { formatEpgDate } from 'utils/epg-date/frontend';

type Props = {
  view: View;
  viewMeta: ViewMeta;
  guideDate?: string;
  isTodaysGuide?: boolean;
};

export function useTitle({
  view,
  viewMeta,
  guideDate,
  isTodaysGuide,
}: Props): string | undefined {
  const language = useUILanguage();
  const t = useTranslation();

  const metaTitle = viewMeta.title?.trim();
  const viewTitle = view.title?.trim();
  const baseTitle = metaTitle || viewTitle;

  if (isTodaysGuide) {
    return baseTitle && `${baseTitle} | ${t('programs')} ${t('today')}`;
  }

  if (guideDate) {
    const formattedDate = formatEpgDate(guideDate, language);

    return baseTitle && `${baseTitle} | ${t('programs')} ${formattedDate}`;
  }

  return baseTitle;
}
