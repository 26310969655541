import { View, ViewMeta } from '@yleisradio/areena-types';
import { useUILanguage } from 'hooks/useUILanguage';
import React from 'react';
import { Query } from 'types/query';
import { GenericMetadata } from './GenericMetadata';
import { SocialMediaTags } from './SocialMediaTags';
import { useTitle } from './useTitle';

interface Props {
  path: string;
  query: Query;
  view: View;
  viewMeta: ViewMeta;
  guideDate: string;
  isTodaysGuide: boolean;
}

export const GuideMetadata: React.FunctionComponent<Props> = ({
  path,
  query,
  view,
  viewMeta,
  guideDate,
  isTodaysGuide,
}) => {
  const { description, image, ogType, robots } = viewMeta;

  const language = useUILanguage();
  const title = useTitle({ view, viewMeta, guideDate, isTodaysGuide });
  const siteName = language === 'sv' ? 'Yle Arenan' : 'Yle Areena';

  return (
    <>
      <GenericMetadata
        description={description}
        path={path}
        query={query}
        robots={robots}
        siteName={siteName}
        title={title}
      />
      <SocialMediaTags
        description={description}
        image={image}
        ogType={ogType}
        path={path}
        query={query}
        siteName={siteName}
        title={title}
      />
    </>
  );
};
