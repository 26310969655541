import Head from 'next/head';
import React from 'react';
import { jsonLdScriptProps } from 'react-schemaorg';
import { SchemaOrgNode } from 'services/schema-org/types';

type Props = {
  nodes: SchemaOrgNode[];
};

export const SchemaOrg: React.FunctionComponent<Props> = ({ nodes }) => {
  return (
    <Head>
      <script
        key="schema-org"
        {...jsonLdScriptProps({
          '@context': 'https://schema.org',
          '@graph': nodes,
        })}
      />
    </Head>
  );
};
