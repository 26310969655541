import { Language } from '@yleisradio/areena-types';
import * as Duration from 'iso8601-duration';
import Head from 'next/head';
import React from 'react';
import { useUILanguage } from 'hooks/useUILanguage';
import { openGraphImageUrl } from 'utils/cloudinary';
import { getCanonicalProgramURL, getCanonicalURL } from 'utils/url';
import { SocialMediaTagsProps } from './SocialMediaTagsProps';

/**
 * Converts ISO 8601 duration to Open Graph compatible duration
 * @see https://ogp.me/#type_video.movie
 * @param duration Duration in ISO 8601 format
 */
function getOpenGraphDuration(duration: string): string | null {
  if (!duration) return null;
  const seconds = Math.floor(Duration.toSeconds(Duration.parse(duration)));
  return seconds >= 1 ? seconds.toString() : null;
}

/**
 * Maps Language to Open Graph compatible locale
 * @see https://ogp.me/#optional
 */
function getOpenGraphLocale(language: Language): string {
  return `${language}_FI`;
}

function isEpisodeGraph(ogType: string): boolean {
  return ogType === 'video.episode';
}

function isVideoGraph(ogType: string): boolean {
  return ogType.startsWith('video.');
}

export const OpenGraph: React.FunctionComponent<SocialMediaTagsProps> = ({
  description,
  duration,
  image,
  ogType = 'website',
  path,
  query,
  releaseDate,
  series,
  siteName,
  title,
  metaImageAspectRatio,
}) => {
  const language = useUILanguage();

  const tags = new Map<string, string>()
    .set('og:type', ogType)
    .set('og:locale', getOpenGraphLocale(language))
    .set(
      'og:locale:alternate',
      getOpenGraphLocale(language === 'sv' ? 'fi' : 'sv')
    )
    .set('og:site_name', siteName);
  if (title) {
    tags.set('og:title', title);
  }
  if (description) {
    tags.set('og:description', description);
  }
  if (path) {
    const url = getCanonicalURL(path, language, query);
    if (url) {
      tags.set('og:url', url);
    }
  }
  if (image) {
    tags.set('og:image', openGraphImageUrl(image, metaImageAspectRatio));
  }
  if (isVideoGraph(ogType) && duration) {
    const ogDuration = getOpenGraphDuration(duration);
    if (ogDuration) {
      tags.set('video:duration', ogDuration);
    }
  }
  if (isVideoGraph(ogType) && releaseDate) {
    tags.set('video:release_date', releaseDate);
  }
  if (isEpisodeGraph(ogType) && series) {
    tags.set('video:series', getCanonicalProgramURL(series, language));
  }

  return (
    <Head>
      {image ? (
        <link
          rel="preload"
          as="image"
          href={openGraphImageUrl(image, metaImageAspectRatio)}
        />
      ) : null}
      {Array.from(tags).map(([property, content]) => (
        <meta key={property} property={property} content={content} />
      ))}
    </Head>
  );
};
