import { Language } from '@yleisradio/areena-types';
import Head from 'next/head';
import { useUILanguage } from 'hooks/useUILanguage';
import { getCanonicalURL } from 'utils/url';
import { Query } from 'types/query';
import { useRouter } from 'next/router';

function isPathSupportedInApp(path: string): boolean {
  // Full list of supported paths available in apple-app-site-association file
  return path.startsWith('/1-');
}

function getAppArgument(
  path: string,
  language: Language,
  query: Query
): string | null {
  return isPathSupportedInApp(path)
    ? getCanonicalURL(path, language, query)
    : null;
}

type Props = {
  path: string;
};

export const AppBanner: React.FunctionComponent<Props> = ({ path }) => {
  const { query } = useRouter();
  const language = useUILanguage();
  const appArgument = getAppArgument(path, language, query);

  if (!appArgument) {
    return null;
  }

  return (
    <Head>
      <meta
        name="apple-itunes-app"
        content={`app-id=542317657, app-argument=${encodeURI(appArgument)}`}
      />
    </Head>
  );
};
