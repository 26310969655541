import type { Language } from '@yleisradio/areena-types';

export function formatEpgDate(date: string, language: Language): string {
  const dateObject = new Date(date);

  return dateObject.toLocaleDateString(`${language}-FI`, {
    weekday: 'short',
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    timeZone: 'UTC',
  });
}

function isoDateFromJSDate(date: Date): string {
  return date.toISOString().substring(0, 10);
}

type SurroundingDates = { previous: string; next: string };

export function getSurroundingEpgDates(currentDate: string): SurroundingDates {
  const reference = new Date(currentDate);

  const previous = new Date(reference);
  previous.setUTCDate(reference.getUTCDate() - 1);

  const next = new Date(reference);
  next.setUTCDate(reference.getUTCDate() + 1);

  return {
    previous: isoDateFromJSDate(previous),
    next: isoDateFromJSDate(next),
  };
}
