import React from 'react';
import { OpenGraph } from './OpenGraph';
import { SocialMediaTagsProps } from './SocialMediaTagsProps';
import { Twitter } from './Twitter';

export const SocialMediaTags: React.FunctionComponent<SocialMediaTagsProps> = (
  props
) => {
  return (
    <>
      <OpenGraph {...props} />
      <Twitter {...props} />
    </>
  );
};
